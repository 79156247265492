// import type {
// 	ICommonMeta,
// 	IVerifyMeta,
// 	IRobotsMeta,
// 	IOgMeta,
// 	ITwitterMeta,
// 	ILink,
// 	ILangLink,
// 	ICommonLink
// } from "@/stores/types/metaTypes";

interface MetaData {
	default_description: string;
	default_title: string;
	description: string;
	locale: string;
	site_name: string;
	title: string;
	og_image?: string;
}

interface paramsType {
	meta_type: number | string;
	link: number | string;
	meta_id: number | string;
	params: object;
}

// export const useMetaParams = (url:string) => {

// 	// const route_name = route.name;
// 	// let route_path = route.path;

// 	const params: paramsType = {
// 		meta_type: "",
// 		link: "",
// 		meta_id: 0,
// 		params: {}
// 	};

// 	if(url){

// 	let path =

// 	route_path = route_path.replace(/^\//, "");
// 	if (website_prefix.test(route_path)) {
// 		route_path = route_path.replace(website_prefix, "");
// 	}
// 	if (/^home/g.test(route_name)) {
// 		params.meta_type = 1;
// 	} else if (/^category/g.test(route_name)) {
// 		params.meta_type = 2;
// 		params.meta_id = (route.params.id as string).match(/\d+$/g) ? (route.params.id as any).match(/\d+$/g)[0] : "";
// 		const query = JSON.parse(JSON.stringify(route.query));
// 		if (query && (query.page || query.count || query.sort_order || query.settab)) {
// 			delete query.page;
// 			delete query.count;
// 			delete query.sort_order;
// 			delete query.settab;
// 		}
// 		if (JSON.stringify(query) !== "{}") {
// 			params.params = query;
// 			params.link = route.params.id;
// 		}
// 	} else if (/^products/g.test(route_name)) {
// 		params.meta_type = 3;
// 		params.meta_id = route.params.id;
// 	} else if (/^answer-question/g.test(route_name)) {
// 		params.meta_type = 4;
// 		params.meta_id = route.params.id;
// 	} else if (/^search_result/g.test(route_name)) {
// 		params.meta_type = 5;
// 		if (JSON.stringify(route.query) !== "{}") {
// 			params.params = route.query;
// 		}
// 	} else if (/^specials/g.test(route_path) || /^support/g.test(route_path)) {
// 		params.meta_type = 6;
// 		params.link = route_path;
// 	} else if (/^case-detail/g.test(route_name)) {
// 		params.meta_type = 8;
// 		params.meta_id = route_path.replace(/\.html$/g, "").match(/\d+$/g)
// 			? route_path.replace(/\.html$/g, "").match(/\d+$/g)[0]
// 			: "";
// 	} else if (/^solutions\//g.test(route_path) || /^get_free_solution_design/g.test(route_name)) {
// 		params.meta_type = 9;
// 		route_path = route_path.replace(/\.html$/g, "").split("/");
// 		params.link = route_path[route_path.length - 1];
// 	} else {
// 		params.meta_type = 7;
// 		route_path = route_path.replace(/\.html$/g, "");
// 		params.link = route_path;
// 	}
// }
// 	return params;
// };

export const useSetMeta = () => {
	const route = useRoute();
	const metaStore = useMeatStore();
	// const fn = useHead
	return (metaData: MetaData) => {
		let og_img = metaData.og_image || "https://resource.fs.com/mall/generalImg/20230214165151cxs9o7.png";
		if (/^\/(de|au|de-en|es|fr|it|jp|mx|ru|sg|uk|cn|hk|tw|mo)?\/?$/.test(route.path)) {
			og_img = "https://resource.fs.com/mall/generalImg/20240131155452bs3pt8.jpg";
		} else if (route.path.includes("/c/switching-4222")) {
			og_img = "https://resource.fs.com/mall/generalImg/20240131155452jfi7g4.jpg";
		}
		const langArr = [
			{ hreflang: "x-default", site: "" },
			{ hreflang: "en", site: "" },
			{ hreflang: "en-GB", site: "/uk" },
			{ hreflang: "en-AU", site: "/au" },
			{ hreflang: "en-NZ", site: "/au" },
			{ hreflang: "es", site: "/mx" },
			{ hreflang: "es-ES", site: "/es" },
			{ hreflang: "es-PT", site: "/es" },
			{ hreflang: "en-SG", site: "/sg" },
			{ hreflang: "en-MY", site: "/sg" },
			{ hreflang: "en-ID", site: "/sg" },
			{ hreflang: "en-TH", site: "/sg" },
			{ hreflang: "en-VN", site: "/sg" },
			{ hreflang: "ja-JP", site: "/jp" },
			{ hreflang: "ru-RU", site: "/ru" },
			{ hreflang: "de-AT", site: "/de" },
			{ hreflang: "de-CH", site: "/de" },
			{ hreflang: "de-DE", site: "/de" },
			{ hreflang: "fr", site: "/fr" },
			{ hreflang: "it", site: "/it" },
			{ hreflang: "en-DE", site: "/de-en" },
			{ hreflang: "en-NL", site: "/de-en" },
			{ hreflang: "en-IT", site: "/de-en" },
			{ hreflang: "en-SE", site: "/de-en" },
			{ hreflang: "en-CH", site: "/de-en" },
			{ hreflang: "en-PL", site: "/de-en" },
			{ hreflang: "en-DK", site: "/de-en" },
			{ hreflang: "en-AT", site: "/de-en" },
			{ hreflang: "en-NO", site: "/de-en" },
			{ hreflang: "en-IE", site: "/de-en" },
			{ hreflang: "en-RO", site: "/de-en" },
			{ hreflang: "en-CZ", site: "/de-en" },
			{ hreflang: "en-FI", site: "/de-en" },
			{ hreflang: "en-PT", site: "/de-en" },
			{ hreflang: "en-GR", site: "/de-en" },
			{ hreflang: "en-HU", site: "/de-en" },
			{ hreflang: "en-BG", site: "/de-en" },
			{ hreflang: "en-SK", site: "/de-en" },
			{ hreflang: "en-RS", site: "/de-en" },
			{ hreflang: "en-LT", site: "/de-en" },
			{ hreflang: "en-HR", site: "/de-en" },
			{ hreflang: "en-SI", site: "/de-en" },
			{ hreflang: "en-LV", site: "/de-en" },
			{ hreflang: "en-EE", site: "/de-en" },
			{ hreflang: "en-MD", site: "/de-en" },
			{ hreflang: "en-MT", site: "/de-en" },
			{ hreflang: "en-AL", site: "/de-en" },
			{ hreflang: "en-BA", site: "/de-en" },
			{ hreflang: "en-IS", site: "/de-en" }
		];
		const website_prefix = /^(au|de|de-en|es|fr|it|jp|mx|ru|sg|uk|cn|hk|tw|mo)\//;

		const { domain } = storeToRefs(metaStore);

		let path = route.path.replace(/^\//, "");
		let site_path = route.path.replace(/^\//, "");

		if (["au", "de", "de-en", "es", "fr", "it", "jp", "mx", "ru", "sg", "uk", "cn", "mo", "hk", "tw"].includes(path)) {
			path = path + "/";
			site_path = site_path + "/";
		}

		if (website_prefix.test(site_path)) {
			site_path = site_path.replace(website_prefix, "");
		}

		site_path = site_path.replace(/^\//, "");

		const url = domain.value + "/" + path;

		const commonMeta: any = [
			{
				charset: "utf-8"
			},
			{
				name: "theme-color",
				content: "#FEFFFF"
			},
			{
				hid: "viewport",
				name: "viewport",
				content: "width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
			},
			{
				"http-equiv": "X-UA-Compatible",
				content: "IE=edge"
			},
			{
				name: "format-detection",
				content: "telephone=no"
			},
			{
				"http-equiv": "Content-Type",
				content: "text/html; charset=UTF-8"
			},
			{
				"http-equiv": "x-dns-prefetch-control",
				content: "on"
			},
			{
				content: "telephone=no",
				name: "format-detection"
			},
			{
				"http-equiv": "imagetoolbar",
				content: "no"
			},
			{
				hid: "description",
				name: "description",
				content: metaData.description || metaData.default_description
			}
		];
		const verifyMeta: any = [
			{
				property: "al:ios:app_name",
				content: "FS.com"
			},
			{
				property: "al:ios:app_store_id",
				content: "1441371183"
			},
			{
				property: "al:ios:url",
				content: "https://itunes.apple.com/us/app/fs-com/id1441371183?l=zh&amp;ls=1&amp;mt=8"
			},
			{
				name: "p:domain_verify",
				content: "58370e85a34b607b08812b6bc4d4946d"
			},
			{
				name: "google-site-verification",
				content: "5-e1avjz6qoODPVSTZoQuek8yZ8OLYh2xHAHUqo1lNU"
			},

			{
				name: "msvalidate.01",
				content: "7F7D99FEC90A7776BCEC1F6416DCCA82"
			},

			{
				name: "facebook-domain-verification",
				content: "fbtgi5esh1g243li7s9kdkligio03z"
			},
			{
				name: "copyright",
				content: "FS.com"
			},
			{
				name: "author",
				content: "FS"
			}
		];
		const robotsMeta: any = [
			{
				hid: "robots",
				name: "robots",
				content: "noindex"
			}
		];

		const ogMeta: any = [
			{
				property: "og:type",
				content: "website"
			},
			{
				hid: "og:title",
				property: "og:title",
				content: metaData.title || metaData.default_title
			},
			{
				hid: "og:url",
				property: "og:url",
				content: url
			},
			{
				hid: "og:image",
				property: "og:image",
				content: og_img
			},
			{
				hid: "og:locale",
				property: "og:locale",
				content: metaData.locale
			},
			{
				hid: "og:description",
				property: "og:description",
				content: metaData.description || metaData.default_description
			},
			{
				hid: "og:site_name",
				property: "og:site_name",
				content: "FS.com"
			}
		];

		const twitterMeta: any = [
			{
				name: "twitter:card",
				content: "summary_large_image"
			},
			{
				name: "twitter:site",
				content: "@FScom_inc"
			},
			{
				name: "twitter:creator",
				content: "@FScom_inc"
			},
			{
				hid: "twitter:title",
				name: "twitter:title",
				content: metaData.title || metaData.default_title
			},
			{
				hid: "twitter:description",
				name: "twitter:description",
				content: metaData.description || metaData.default_description
			},

			{
				hid: "twitter:image",
				property: "twitter:image",
				content: og_img
			}
		];
		const commonLink: any = [
			{
				rel: "icon",
				type: "image/x-icon",
				href: "https://resource.fs.com/mall/generalImg/202302151830494aalh9.ico"
			},
			{
				rel: "shortcut icon",
				href: "https://resource.fs.com/mall/generalImg/202302151830494aalh9.ico"
			},
			{
				rel: "apple-touch-icon",
				href: "https://resource.fs.com/mall/generalImg/202302151830494aalh9.ico"
			}
		];

		const link: any = [];
		const langLink: any = [];

		link.push({
			rel: "canonical",
			href: url
		});
		console.log("111111111");
		console.log(site_path);

		langArr.forEach(item => {
			langLink.push({
				rel: "alternate",
				hreflang: item.hreflang,
				href: `${domain.value}${item.site}${site_path ? `/${site_path}` : site_path}`
			});
		});
		metaStore.$patch(state => {
			state.title = metaData.title || metaData.default_title;
			state.default_title = metaData.default_title;
			state.description = metaData.description || metaData.default_description;
			state.default_description = metaData.default_description;
			state.locale = metaData.locale;
			state.site_name = metaData.site_name;
			state.commonMeta = commonMeta;
			state.verifyMeta = verifyMeta;
			state.robotsMeta = robotsMeta;
			state.ogMeta = ogMeta;
			state.twitterMeta = twitterMeta;
			state.commonLink = commonLink;
			state.link = link;
			state.langLink = langLink;
		});
	};
};
